// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import chat from "@src/views/apps/chat/store/reducer";
import todo from "@src/views/apps/todo/store/reducer";
import users from "@src/views/apps/usersRoute/user/store/reducer";
import admins from "../../views/apps/adminRoute/user/store/reducer";
import email from "@src/views/apps/email/store/reducer";
import invoice from "@src/views/apps/invoice/store/reducer";
import calendar from "@src/views/apps/calendar/store/reducer";
import ecommerce from "@src/views/apps/ecommerce/store/reducer";
import dataTables from "@src/views/tables/data-tables/store/reducer";
import books from "@src/views/apps/bookRoute/user/store/reducer";
import roles from "@src/views/apps/roleSetting/user/store/reducer";
import packages from "../../views/apps/packageRoute/user/store/reducer";
import audios from "../../views/apps/audioRoute/user/store/reducer";
import videos from "../../views/apps/videoRoute/user/store/reducer";
import notifications from "../../views/apps/notificationSetting/user/store/reducer";
import addons from "../../views/apps/addonRoute/user/store/reducer";
import packagesPurchase from "../../views/apps/usersRoute/user/list/packagePurchase/user/store/reducer";
import addonsPurchase from "../../views/apps/usersRoute/user/list/addonPurchase/user/store/reducer";
import addonSubscriptions from "../../views/apps/addonSubscription/user/store/reducer";
import packageSubscriptions from "../../views/apps/packageSubscription/user/store/reducer";
import addonSelection from "../../views/apps/addonSelection/user/store/reducer";
import userLog from "../../views/apps/userLog/user/store/reducer";
import auditLog from "../../views/apps/auditLog/user/store/reducer";
import adsReducer from "../../views/apps/ads route/user/store/reducer";
import socket from "../../views/apps/socket.io/store/reducer";
import authors from "../../views/apps/authors/user/store/reducer";
import jr from "../../views/apps/journalandresearch/user/store/reducer";

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  books,
  admins,
  roles,
  packages,
  audios,
  videos,
  notifications,
  addons,
  packagesPurchase,
  addonsPurchase,
  addonSubscriptions,
  packageSubscriptions,
  addonSelection,
  userLog,
  auditLog,
  adsReducer,
  socket,
  authors,
  jr,
});

export default rootReducer;
