// ** Initial State
const initialState = {
  alladss: [],
  token: JSON.parse(localStorage.getItem("accessToken"))
}

// console.log('sssssssssssss', selectedUser)

const adsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_ADSS':
      return { ...state, alladss: action.data }
    case 'DELETE_ADD':
      console.log(action.data)
      return { ...state, alladss: action.data }
    default:
      return { ...state }
  }
}
export default adsReducer
