// ** Initial State
const initialState = {
  allData: [],
  allUserLogs: [],
  data: [],
  total: 1,
  params: {},
  currentUser: null,
  selectedUser: null,
  selectedVideo: localStorage.getItem("selectedVideo") === 'undefined' ? null : JSON.parse(localStorage.getItem("selectedVideo")),
  bookEdit: false,
  customPagination: null,
  token: JSON.parse(localStorage.getItem("accessToken")),
  found_results: '',
  entries_per_page: '',
  total_results: ''

}

// console.log('sssssssssssss', selectedUser)

const userLog = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_USERLOGS':
      return { ...state, allUserLogs: action.data, customPagination: action.customTotal,entries_per_page: action.entries_per_page, found_results: action?.found_results, total_results: action?.total_results  }
    case 'DELETE_USERLOG':
      console.log(action.data)
      return { ...state, allUserLogs: action.data }
    case 'CLEAR_USERLOGS':
      return {...state, allUserLogs: []}
    case 'VIDEO_PAGE_CHANGE':
      return {...state, allUserLogs: []}
    default:
      return { ...state }
  }
}
export default userLog
