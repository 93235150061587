// ** Initial State
const initialState = {
  allAuthors: [],
  token: JSON.parse(localStorage.getItem("accessToken")),
  found_results: '',
  entries_per_page: '',
  total_results: '',
  selectedAuthor: {}
}

// console.log('sssssssssssss', selectedUser)

const authors = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_AUTHORS':
      return { ...state, allAuthors: action.data, customPagination: action.customTotal, entries_per_page: action.entries_per_page, found_results: action?.found_results, total_results: action?.total_results }
    case 'DELETE_BOOK':
      console.log(action.data)
      return { ...state, allAuthors: action.data }
    case 'EDITAUTHOR': 
      return {...state, selectedAuthor: action.data}
    case 'UPDATEFINISHED':
      return {...state, selectedAuthor: {}}
    default:
      return { ...state }
  }
}
export default authors
