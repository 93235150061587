// ** Initial State
const initialState = {
  socketNotis: [],
  token: JSON.parse(localStorage.getItem("accessToken"))
}

// console.log('sssssssssssss', selectedUser)

const socket = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_SOCKET_NOTIS':
      return { ...state, socketNotis: [...action.data] }
    case 'ADD_NEW_NOTIS':
      return {...state, socketNotis: [action.data, ...state?.socketNotis]}
    case 'DELETE_SINGLE_SOCKET':
      return {...state, socketNotis: [...action.data]}
    default:
      return { ...state }
  }
}
export default socket
